import { Button, Center, P1, P2, Section, Spacer, useConfig } from "@mailbrew/uikit";
import { MailbrewLogo } from "components/brand/MailbrewLogo";
import useResetError from "hooks/useResetError";
import Router, { useRouter } from "next/router";
import queryString from "query-string";
import { useSelector } from "react-redux";
import { appErrorSelector } from "reducers/appReducer";
import urls from "urls";
import { signupWithTwitter } from "utils/twitterAuth";
import { SignupCouponBadge } from "./SignupForm";
import StyledLink from "./StyledLink";

const ChooseSignupMethod = () => {
  const config = useConfig();

  const twitterSignupError = useSelector(appErrorSelector("signup:twitter"));
  useResetError("signup:twitter");

  const router = useRouter();
  const coupon = router.query.coupon;

  const handleSignupWithTwitter = () => {
    signupWithTwitter((twitterSignupId) => goToSignupDetails({ twitterSignupId }));
  };

  const handleSignupWithEmail = () => {
    goToSignupDetails();
  };

  return (
    <>
      <Section pt={0} center width="400px">
        <Center mb={10}>
          <P1 align="center" color={config.colors.c3} mb={1.6}>
            Create account
          </P1>
          <MailbrewLogo size="38px" />
        </Center>
        <SignupCouponBadge coupon={coupon} />
        {coupon && <Spacer />}
        <Button width="100%" icon="twitter" color="rgba(29,161,242,1.00)" onClick={handleSignupWithTwitter}>
          Sign Up with Twitter
        </Button>
        {twitterSignupError && (
          <P2 color={config.colors.error} align="center">
            {twitterSignupError}
          </P2>
        )}
        <Button mt={3} mb={5} variant="white" width="100%" icon="emailBold" onClick={handleSignupWithEmail}>
          Sign Up with Email
        </Button>
        <P2 align="center">
          Already registered?{" "}
          <StyledLink variant="link" to={urls.login()}>
            Log in here.
          </StyledLink>
        </P2>
      </Section>
    </>
  );
};

export function goToSignupDetails(options = {}) {
  const { twitterSignupId } = options;

  let qs = queryString.parse(window.location.search);

  if (twitterSignupId) {
    qs["tid"] = twitterSignupId;
  }

  qs = queryString.stringify(qs);
  if (qs.length > 0) qs = "?" + qs;

  Router.push(urls.signupDetails() + qs);
}

export default ChooseSignupMethod;
